$(window).load(function () {
/*nav途中固定*/
  var header = $('header'),
    nav = $('.header-lower'),
    offset = nav.offset();

  $(window).scroll(function () {
    if($(window).scrollTop() > offset.top - 2) {
      header.addClass('fixed');
    } else {
      header.removeClass('fixed');
    }
  });
});

//ヘッダをfixedにしていることで生じるアンカーリンクの誤差修正
$(function () {
    var headerHight = 120; //ヘッダの高さ
    $('a[href^=#]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top-headerHight; //ヘッダの高さ分位置をずらす
        $("html, body").animate({scrollTop:position}, 550, "swing");
        return false;
    });
});

//sp menu
$(function(){
  $('.sp-menu').click(function() {
    $('.header-lower').toggleClass('active');
    $('.sp-menu').toggleClass('active');
  });
});

//FAQ
$('.faq .q').on('click', function(){
  $(this).next('.a').slideToggle(400);
});

window.addEventListener('DOMContentLoaded', function(){
  new ScrollHint('.js-scrollable', {
    i18n: {
      scrollable: 'スクロールできます'
    }
  });
});
